import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axios from "@/lib/axios.client";
import { Category } from "@/lib/api/auth/type";

const partnerCategory = (options = {}) => {
  const queryClient = useQueryClient();

  const category = async (body: Category) => {
    const payload = await axios.post(
      "/partner-auth/set-partner-category",
      body
    );
    return payload;
  };
  const { mutate, isPending, data, isSuccess, isError } = useMutation({
    mutationFn: category,
    onSuccess: async (payload) => {
      await queryClient.invalidateQueries({ queryKey: ["category"] });
      toast.success("category set successfully! 🎉");
    },
    onError: (err: any) => {
      toast.error(
        err.response.data.message ? err.response.data.message : err.message
      );
    },
  });
  return {
    mutate: (body: Category) => mutate(body),
    isPending,
    data,
    isSuccess,
    isError,
  };
};

export default partnerCategory;
