import register from "./register";
import login from "./login";
import changePassword from "./changePassword";
import resetPassword from "./resetPassword";
import partnerCategory from "./partnerCategory";
import SocialAuth from "./SocialAuth";

const authQueries = {
  register,
  login,
  changePassword,
  resetPassword,
  partnerCategory,
  SocialAuth,
};

export default authQueries;
