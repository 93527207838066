import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axios from "@/lib/axios.client";
import { UserLogin } from "@/lib/api/auth/type";
import { useRouter } from "next/navigation";
import { storeToken } from "@/lib/helper";
import { setCookie } from "cookies-next";

const login = () => {
  const queryClient = useQueryClient();
  const router = useRouter();

  const loginUser = async (body: UserLogin) => {
    const payload = await axios.post("/partner-auth/login", body);
    setCookie("auth_token", payload.data.idToken);
    setCookie("category", payload.data.category);
    setCookie("license", payload?.data?.partner?.practicingLicenseUrl);
    storeToken("authToken", payload.data.idToken);
    storeToken("userId", payload.data.partner.id);
    storeToken("email", payload.data.partner.email);
    storeToken("referralId", payload.data.partner.referralCode);
    storeToken("category", payload.data.category);
    storeToken("walletAddress", payload.data.partner.walletAddress);
    storeToken(
      "walletEncryptedKeystore",
      payload.data.partner.walletEncryptedKeystore
    );
    return payload;
  };

  const { mutate, isPending, data, isSuccess, isError } = useMutation({
    mutationFn: loginUser,
    onSuccess: async (payload) => {
      await queryClient.invalidateQueries({ queryKey: ["login"] });
      toast.success("Logged in successfully! 🎉");

      switch (payload?.data?.category) {
        case "Doctor":
          if (
            payload?.data?.partner?.academicCertificateUrl &&
            payload?.data?.partner?.practicingLicenseUrl
          ) {
            router.push("/doctor");
          } else {
            router.push("/onboarding");
          }
          break;

        case "Laboratory":
        case "Pharmacy":
        case "Hospital":
          if (payload?.data?.partner?.practicingLicenseUrl) {
            router.push(`/${payload.data.category.toLowerCase()}`);
          } else {
            router.push("/onboarding");
          }
          break;

        default:
          router.push("/onboarding");
          break;
      }
    },
    onError: (err: any) => {
      toast.error(
        err.response.data.message ? err.response.data.message : err.message
      );
    },
  });

  return {
    mutate: (body: UserLogin) => mutate(body),
    isPending,
    data,
    isSuccess,
    isError,
  };
};

export default login;
