import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axios from "@/lib/axios.client";
import { UserRegister } from "@/lib/api/auth/type";
import { useRouter } from "next/navigation";

const register = (options = {}) => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const registerUser = async (body: UserRegister) => {
    const payload = await axios.post("/partner-auth/register", body);
    return payload;
  };
  const { mutate, isPending, data, isSuccess, isError } = useMutation({
    mutationFn: registerUser,
    onSuccess: async (payload) => {
      await queryClient.invalidateQueries({ queryKey: ["register"] });
      router.push("/login");
    },
    onError: (err: any) => {
      toast.error(
        err.response.data.message ? err.response.data.message : err.message
      );
    },
  });
  return {
    mutate: (body: UserRegister) => mutate(body),
    isPending,
    data,
    isSuccess,
    isError,
  };
};

export default register;
