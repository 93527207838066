import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axios from "@/lib/axios.client";
import { ResetPassword } from "@/lib/api/auth/type";

const resetPassword = (options = {}) => {
  const queryClient = useQueryClient();

  const forgotPassword = async (body: ResetPassword) => {
    const payload = await axios.post(
      "/partner-auth/send-password-reset-email",
      body
    );
    return payload;
  };
  const { mutate, isPending, data, isSuccess, isError } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: async (payload) => {
      await queryClient.invalidateQueries({ queryKey: ["reset"] });
      toast.success("link sent successfully! 🎉");
      window.location.reload();
    },
    onError: (err: any) => {
      toast.error(
        err.response.data.message ? err.response.data.message : err.message
      );
    },
  });
  return {
    mutate: (body: ResetPassword) => mutate(body),
    isPending,
    data,
    isSuccess,
    isError,
  };
};

export default resetPassword;
